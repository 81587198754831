<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0"><slot /></h3>
      </div>
    </div>

    <div class="pl-lg-4">
      <base-input label="Krótki opis">
        <rich-editor-component
          v-model="cShortDescription"
        />
      </base-input>
      <base-input label="Długi opis">
        <rich-editor-component
          v-model="cLongDescription"
        />
      </base-input>
    </div>
  </card>
</template>

<script>
import RichEditorComponent from "@/components/RichEditor/RichEditorComponent.vue";

export default {
  name: 'GenericDescriptionForm',
  components: {RichEditorComponent},
  props: {
    formData: Object,
  },
  computed: {
    cShortDescription: {
      get() {
        return this.formData?.description?.short ?? ''
      },
      set(value) {
        this.formData.description.short = value
      }
    },
    cLongDescription: {
      get() {
        return this.formData?.description?.long ?? ''
      },
      set(value) {
        this.formData.description.long = value
      }
    },
  }
};
</script>


