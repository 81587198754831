<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
      <div class="row">
        <div class="col-xl-9 col-md-6">
        </div>
        <div class="col-xl-3 col-md-6">
<!--          <stats-card title="Liczba"-->
<!--                      type="gradient-info"-->
<!--                      :sub-title="itemData.qty"-->
<!--                      icon="ni ni-cart"-->
<!--          >-->

<!--          </stats-card>-->


          <card gradient="info" class="border-0">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0 text-white">Stan magazynu</h5>
                <span class="h2 font-weight-bold mb-0 text-white">{{ itemData.qty }} szt.</span>
              </div>
              <div class="col-auto">
                <div class="icon icon-shape bg-white text-dark rounded-circle shadow">
                  <i class="ni ni-cart"></i>
                </div>
              </div>
            </div>
<!--            <p class="mt-3 mb-0 text-sm">-->
<!--              <span class="text-white mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>-->
<!--              <span class="text-nowrap text-light">Since last month</span>-->
<!--            </p>-->
          </card>

        </div>
        </div>
    </base-header>

    <div class="container-fluid mt--6">

      <div class="row">
        <div class="col-lg-6 col-xl-5 order-xl-1">
          <product-photos-manage v-model="cPhotos" @added="handleAdded" />
          <product-name-form :form-data="itemData"
                             :category-options="selectOptions.categories"
                             :line-options="selectOptions.lines"
                             :tag-options="selectOptions.tags"
                             :recommendation-options="selectOptions.recommendations"
          />
          <generic-description-form :form-data="itemData" />
          <generic-seo-form :form-data="itemData" />
        </div>

        <div class="col-lg-6 col-xl-7 order-xl-1">
          <product-price-form :form-data="itemData"/>
          <product-property-form :form-data="itemData"
                                 :property-options="selectOptions.properties"
                                 @added="handleAdded"
          />
        </div>

      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary" @click="saveProduct" :disabled="submitDisabled">Zapisz</button>
        <button class="btn btn-primary" @click="repairMissingImages" :disabled="submitDisabled">Napraw zdjęcia</button>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapMutations} from 'vuex'

import ProductNameForm from "@/views/Store/Product/ProductNameForm";
import ProductPriceForm from "@/views/Store/Product/ProductPriceForm";
import ProductPropertyForm from "@/views/Store/Product/ProductPropertyForm";
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import ProductDescriptionForm from "@/views/Store/Product/ProductDescriptionForm";
import ProductSeoForm from "@/views/Store/Product/ProductSeoForm";
import ProductPhotosManage from "@/components/TailwindComponents/ProductPhotosManage";
import GenericSeoForm from "@/views/Store/Generic/GenericSeoForm.vue";
import GenericDescriptionForm from "@/views/Store/Generic/GenericDescriptionForm.vue";

const {mapState } = createNamespacedHelpers('ProductViewModule');

export default {
  name: 'ProductView',
  components: {
    GenericDescriptionForm,
    GenericSeoForm,
    ProductPhotosManage,
    BreadcrumbHeader,
    ProductPriceForm,
    ProductPropertyForm,
    ProductNameForm,
    ProductDescriptionForm,
    ProductSeoForm
  },
  data() {
    return {
      submitDisabled: false
    }
  },
  computed: {
    ...mapState({
      itemData: state => state.item,
      selectOptions: state => state.options,
    }),
    cBaseGrossPrice() {
      try {
        return this.parsePrice(this.itemData.baseGrossPrice)
      } catch (e) {
        return 0
      }
    },
    cFixedDiscount() {
      try {
        return (this.itemData.fixedDiscount ?? -1) < 0 ? -1 : this.parsePrice(this.itemData.fixedDiscount)
      } catch (e) {
        return -1
      }
    },
    cTaxRate() {
      try {
        return '' + (this.itemData.taxRate * 100)
      } catch (e) {
        return 0
      }
    },
    cBaseGrossPriceVuexBinding() {
      try {
        return {
          baseGrossPrice: this.cBaseGrossPrice
        }
      } catch (e) {
        return {}
      }
    },
    cBaseNetPriceVuexBinding() {
      try {
        return {
          baseNetPrice: this.parsePrice(this.itemData.baseNetPrice)
        }
      } catch (e) {
        return {}
      }
    },
    cFixDiscountVuexBinding() {
      try {
        return {
          fixedDiscount: +this.cFixedDiscount
        }
      } catch (e) {
        return {
        }
      }
    },
    cTaxRateVuexBinding() {
      try {
        return {
          taxRate: +this.cTaxRate
        }
      } catch (e) {
        return {}
      }
    },
    cPhotos: {
      get() {
        const image = this.itemData.image
        const gallery = this.itemData.gallery
        try {
          return [
            {
              order: 0,
              media: 'picture',
              thumbUrl: image.thumbUrl,
              url: image.url
            },
            ...gallery.map( image => ({
              order: image.order,
              media: 'picture',
              thumbUrl: image.thumbUrl,
              url: image.url
            }))
          ]
        } catch (e) {
          return []
        }
      },
      set(items) {
        const image = {
          ...items[0]
        }
        this.setImages({
          image,
          gallery: [...items.filter( (item, index) => index )]
        })
      }
    },

      breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Produkty', link: {name: 'product-list'}},
        {title: `Produkt ID: ${this.$route.params.id}`}
      ];
    },
  },
  methods: {
    ...mapMutations('ProductViewModule', ['setImages']),
    handleAdded(event) {
      const gallery = [
        ...this.itemData.gallery,
        ...event.map( (image, index) => ({
          media: 'picture',
          order: this.itemData.gallery.length + index,
          url: image,
          thumbUrl: image
        }))
      ]
      this.setImages({
        gallery
      })
    },
    parsePrice(price) {
      return '' + Math.ceil(parseFloat(price) * 100)
    },
    updateNetPrice() {
      this.itemData.baseNetPrice = parseFloat((+this.cBaseGrossPrice) / +(`1.${this.itemData.taxRate}`) / 100).toFixed(2)
    },
    async saveProduct() {
      try {
        this.submitDisabled = true;
        const { active, archived, available, ean, name, seo, description, category, line, discount, tags, properties, recommendations } = this.itemData
        await this.$store.dispatch('ProductViewModule/updateProduct', {
          token: this.$store.state.Auth.token,
          id: this.$route.params.id,
          data: {
            active,
            archived,
            available,
            ean: ean.trim(),
            name: name.trim(),
            description,
            ...this.cBaseGrossPriceVuexBinding,
            ...this.cBaseNetPriceVuexBinding,
            ...this.cFixDiscountVuexBinding,
            ...this.cTaxRateVuexBinding,
            image: {
              ...this.itemData.image,
              url: this.itemData.image.url.split('https://cdn.clochee.com/prod/').join(''),
              thumbUrl: this.itemData.image.thumbUrl.split('https://cdn.clochee.com/prod/').join('')
            },
            gallery: this.itemData.gallery.map( item => ({ ...item, url: item.url.split('https://cdn.clochee.com/prod/').join(''), thumbUrl: item.thumbUrl.split('https://cdn.clochee.com/prod/').join('') })),
            // discount: +(discount || 0),
            tags: tags.map( tag =>  `/api/v1/rest/product-tags/${tag}`),
            category: `/api/v1/rest/product-categories/${category}`,
            // recommendations: recommendations.map( product => `/api/v1/rest/products/${product}`),
            line: `/api/v1/rest/product-lines/${line}`,
            properties,
            seo
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.submitDisabled = false;
      }
    },
    async repairMissingImages() {
      await this.$store.dispatch('ProductViewModule/updateProduct', {
        token: this.$store.state.Auth.token,
        id: this.$route.params.id,
        data: {
          image: {
            "media": "picture",
            "url": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml.webp",
            "thumbUrl": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml_th.webp",
            "iconUrl": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml_ico.jpg",
            "host": "https://cdn.clochee.com/prod"
          },
          gallery: [
            {
              "order": 1,
              "media": "picture",
              "url": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml-b2_2.webp",
              "thumbUrl": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml-b2_2_th.webp",
              "host": "https://cdn.clochee.com/prod"
            },
            {
              "order": 2,
              "media": "picture",
              "url": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml-b_2.webp",
              "thumbUrl": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml-b_2_th.webp",
              "host": "https://cdn.clochee.com/prod"
            },
            {
              "order": 3,
              "media": "picture",
              "url": "wonder-tan-brazujaca-pianka-do-ciala-i-twarzy_2.webp",
              "thumbUrl": "wonder-tan-brazujaca-pianka-do-ciala-i-twarzy_2_th.webp",
              "host": "https://cdn.clochee.com/prod"
            }
          ]
        }
      })
    }
  },
  beforeMount() {
    this.$store.dispatch('ProductViewModule/fetchItem', {
      token: this.$store.state.Auth.token,
      id: this.$route.params.id,
    });
  },
  watch: {
    cBaseGrossPrice() {
      this.updateNetPrice();
    },
    cTaxRate() {
      this.updateNetPrice();
    }
  }
};
</script>
