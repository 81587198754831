<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">SEO</h3>
      </div>
    </div>

    <div class="pl-lg-4">
      <base-input type="text"
                  label="Meta title"
                  placeholder="Wpisz meta title"
                  v-model="formData.seo.title"
      ></base-input>
      <base-input label="Meta description">
        <textarea placeholder="Wpisz meta description" class="form-control" id="product-seo-description" rows="15" v-model="formData.seo.description"></textarea>
      </base-input>
    </div>
  </card>
</template>

<script>
export default {
  name: 'GenericSeoForm',
  props: {
    formData: Object,
  },
};
</script>
